import { RouterProvider, createBrowserRouter, Link } from 'react-router-dom'
import './App.css';
import React, { useEffect, useState } from 'react';
import { AppProvider } from './AppContext';
import { Home } from './pages/Home';
import { Frens } from './Components/Friends/Frens';
import { Games } from './Components/Games/Games';
import { Tasks } from './Components/Tasks/Tasks';
import { TeamExplorer } from './Components/Team/TeamExplorer';
import { Team } from './Components/Team/Team';
import PetChoosing from './Components/Pet/PetChoosing/PetChoosing';
import PetNaming from './Components/Pet/PetNaming/PetNaming';
import MainPet from './Components/Pet/MainPet/MainPet';
import { Boost } from './Components/Boost/Boost';
import Shop from './Components/Shop/Shop';
import League from './Components/League/League';
import Map from './Components/Map/Map';
import PetCard from './Components/Pet/MainPet/PetCard/PetCard';
import BuyCoins from './Components/BuyCoins/BuyCoins';
import { InviteFriend } from './Components/Friends/InviteFriend';
import Settings from './Components/Settings/Settings';
import LenguaguePicker from './Components/Settings/LenguaguePicker';
import axios from "axios";
import { checkLocation } from './http';
import Listing from './Components/Listing/Listing';
import Pump from './Components/Pump/Pump';
import NFTMintBlock from './Components/NFT/NFTMintBlock';
import BuyCoin from './Components/BuyCoin/BuyCoin';
import PanameraDrop from './Components/PanameraDrop/PanameraDrop';
import PanameraFooter from './Components/PanameraDrop/PanameraFooter';
import PanameraHome from './Components/PanameraDrop/PanameraHome';

import SlexListing from './Components/SlexListing/SlexListing';
const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/frens',
    element: <Frens />
  },
  {
    path: '/games',
    element: <Games />
  },
  {
    path: '/earn/:tab',
    element: <Tasks />
  },
  {
    path: '/team-explorer',
    element: <TeamExplorer />
  },
  {
    path: '/team/:teamId',
    element: <Team />
  },
  {
    path: '/shop/:tab',
    element: <Shop />
  },
  {
    path: '/choose_pet',
    element: <PetChoosing />
  },
  {
    path: '/PetNaming/:PetId',
    element: <PetNaming />
  },
  {
    path: '/MainPet',
    element: <MainPet />
  },
  {
    path: '/PetCard',
    element: <PetCard />
  },
  {
    path: '/boosts',
    element: <Boost />
  },
  {
    path: '/league/:leagueName',
    element: <League />
  },
  {
    path: '/buy',
    element: <BuyCoins />
  },
  {
    path: '/map',
    element: <Map />
  },
  {
    path: '/friendsList',
    element: <InviteFriend />
  },
  {
    path: '/settings',
    element: <Settings />
  },
  {
    path: '/lenguague_picker',
    element: <LenguaguePicker />
  },
  {
    path: '/lis',
    element: <Listing />
  },
  {
    path: '/pump',
    element: <Pump />
  },
  {
    path: '/nft',
    element: <NFTMintBlock />
  },
  {
    path: '/coin',
    element: <BuyCoin />
  },
  {
    path: '/PanameraDrop',
    element: <PanameraDrop />
  },
  {
    path: '/PanameraFooter',
    element: <PanameraFooter />
  },
  {
    path: '/PanameraHome',
    element: <PanameraHome />
  },
  {
    path: '/listing',
    element: <SlexListing />
  }
]);

function App() {

  // useEffect(() => {
  //   if (window.Telegram?.WebApp) {
  //     window.Telegram.WebApp.ready();

  //     window.Telegram.WebApp.expand();

  //     window.Telegram.WebApp.requestFullscreen();

  //     if (window.Telegram.WebApp.disableVerticalSwipes) {
  //       window.Telegram.WebApp.disableVerticalSwipes();
  //     } else {
  //       console.warn('Метод disableVerticalSwipes не поддерживается в этой версии Telegram WebApp.');
  //     }

  //   } else {
  //     console.error('Telegram WebApp is not available.');
  //   }
  // }, []);


  if (window.tg && !window.tg.isExpanded) {
    window.tg.expand();
    
  }
  const [ip, setIP] = useState("");
  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    console.log(res.data);
    setIP(res.data.ip);

    checkLocation(ip)
  };

  useEffect(() => {
    getData();
  }, []);
  console.log(ip)
  return (
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  );
}

export default App;