import React from 'react';
import panamera from './panameraHome.png';
import drop1 from './drop.png';
import people from './people.png';
import { useNavigate } from 'react-router-dom';
import { useAppData } from '../../AppContext';
import Back from '../Back';
import { useTranslation } from 'react-i18next'; 

function PanameraHome() {
  const navigate = useNavigate();
  const { dropUsersCount, drop, setShowDropPopup, isDropActive } = useAppData();
  const { t } = useTranslation();  

  const handleClick = () => {
    setShowDropPopup(false);
    navigate('/PanameraDrop');
  };
  return (
    <div className="outer-container_panamera_home">
      <div className="container_glow_panamera_home">
        <Back />
        <span className="close" onClick={() => setShowDropPopup(false)}>&times;</span>
        <div className="button-count">
          <img className="button-icon" src={people} alt="People" />
          <span>{dropUsersCount}</span>
        </div>

        <img src={panamera} style={{ width: '100%' }} alt="Panamera" />
        <h1 style={{ textAlign: 'center' }}>{t('congratulations')}</h1> 
        <div className="usdt_balance">
          {drop}$<span style={{ fontSize: '14px', marginLeft: '10px' }}>{t('usdt')}</span> 
        </div>

        <button className="custom-button">
          <span className="button-label" onClick={handleClick}>{t('activate')}</span> 
          <div className="button-count">
            <img className="button-icon" src={people} alt="People" />
            <span>{dropUsersCount}</span>
          </div>
        </button>
        <button className="custom-button-later" onClick={() => setShowDropPopup(false)}>
          Continue 
        </button>
        
      </div>
    </div>
  );
}

export default PanameraHome;
