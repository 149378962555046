import React from 'react';
import panamera from './panamera_no.png';
import drop from './drop.png';
import people from './people.png';
import { useNavigate } from 'react-router-dom';
import { useAppData } from '../../AppContext';
import Back from '../Back';
import { useTranslation } from 'react-i18next';

function PanameraFooter() {
  const navigate = useNavigate();
  const { dropUsersCount } = useAppData();
  const { t } = useTranslation();  

  return (
    <div className="outer-container_panamera">
      <div className="container_glow_panamera_foot">
        <Back />
        <img src={panamera} style={{ width: '100%' }} alt="Panamera" />
        <h1>{t('raffle')}</h1> 
        <img src={drop} style={{ width: '70%' }} alt="Drop" />

        <button className="custom-button">
          <span className="button-label" onClick={() => navigate('/PanameraDrop')}>
            {t('activate')}  
          </span>
          <div className="button-count">
            <img className="button-icon" src={people} alt="People" />
            <span>{dropUsersCount}</span>
          </div>
        </button>


      </div>
    </div>
  );
}

export default PanameraFooter;
